import { getDefaultLimitRepresentation } from "common/utils/limitRepresentation";
import { CleaningLimitPolicyResidueType } from "./enums";
import { PolicyConfigUI } from "./types";
import { DEFAULT_UNITS } from "./utils/default-units";

export const defaultCleaningPolicy: PolicyConfigUI = {
  useDosage: false,
  useToxicity: false,
  useProductLimit: false,
  useLd50: false,
  useARL: false,
  arl: { value: 10, unit: DEFAULT_UNITS.L5 },
  residueLimit: { value: 10, unit: DEFAULT_UNITS.L3 },
  useResidueLimit: false,
  ignoreCombo: false,
  useGlobalRecoveryPercentage: false,
  globalRecoveryPercentage: 100,
  residueType: CleaningLimitPolicyResidueType.Chemical,
  useSAR: false,
  limitRepresentation: getDefaultLimitRepresentation(),
  useFirstAvailableCriteria: false,
  intermediateArl: 0,
  activesArl: { value: 10, unit: DEFAULT_UNITS.L5 },
  useSampleLimit: false,
  swabSampleLimit: 10,
  rinseSampleLimit: 10,
  useFixedSampleLimits: false,
  fixedSwabLimit: 10,
  fixedRinseLimit: 10
};

export const ROOM_GRADE_ID = "roomGradeId";

export const geometryOptions = {
  cylinder: {
    formula: "2*π*r*h+2*π*(r**2)",
    visualFormula: "2π * r * h + 2 * πr^2",
    label: "Cylindrical",
    inputs: {
      r: "Radius (r)",
      h: "Height (h)"
    }
  },
  circle: {
    formula: "π*r**2",
    visualFormula: "πr^2",
    label: "Circle",
    inputs: {
      r: "Radius (r)"
    }
  },
  circular_ring: {
    formula: "π*(r**2)-π*(R1**2)",
    visualFormula: "πr^2 - πR1^2",
    label: "Circular ring",
    inputs: {
      r: "Radius (r)",
      R1: "Inner radius (R1)"
    }
  },
  cube: {
    formula: "6*(l**2)",
    visualFormula: "6*L^2",
    label: "Cube",
    inputs: {
      l: "Length (L)"
    }
  },
  cone: {
    formula: "π*x*(x+Math.sqrt(y)+x**2)",
    visualFormula: "πr (r+ sqrt(h) + r^2)",
    label: "Cone",
    inputs: {
      x: "Radius (r)",
      y: "Height (h)"
    }
  },
  square: {
    formula: "l**2",
    visualFormula: "l^2",
    label: "Square",
    inputs: {
      l: "Length (L)"
    }
  },
  rectangle: {
    formula: "l*h",
    visualFormula: "l*h",
    label: "Rectangle",
    inputs: {
      l: "Length (l)",
      h: "Height (h)"
    }
  },
  triangle: {
    formula: "(1/2)*b*h",
    visualFormula: "1/2 * b * h",
    label: "Triangle",
    inputs: {
      b: "base (b)",
      h: "Height (h)"
    }
  }
};
